import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import DashboardItem from "../../data/header.json";

import menuImg from "../../public/images/menu-img/menu-img-2.png";
import { useAppContext } from "@/context/Context";

const Nav = () => {
  const router = useRouter();
  const { showItem, setShowItem } = useAppContext();
  console.log("🚀 ~ Nav ~ showItem:", showItem)

  const isActive = (href) => router.pathname === href;
  console.log("🚀 ~ Nav ~ router.pathname:", router.pathname)

  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const sections = document.querySelectorAll("section");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.6 } // Adjust this threshold as needed
    );

    sections.forEach((section) => observer.observe(section));

    console.log("🚀 ~ Nav ~ activeSection:", activeSection)
    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <>
      <ul className="mainmenu">
        <li>
          <Link className={activeSection == 'hero' && isActive('/') ? 'active' : ''} href={isActive('/') ? "#hero" : "/"}>Home</Link>
        </li>
        <li>
          <a className={activeSection === 'services' ? 'active' : ''} href={isActive('/') ? `#services` : '/#services'}>Services</a>
        </li>
        <li>
          <a className={activeSection === 'about' ? 'active' : ''} href={isActive('/') ? `#about` : '/#about'}>About</a>
        </li>
        <li>
          <Link className={isActive('/contact') ? 'active' : ''} href="/contact">Contact</Link>
        </li>
        <li>
          <a className={activeSection === 'pricing' ? 'active' : ''} href={isActive('/') ? `#pricing` : '/#pricing'}>Pricing</a>
        </li>
      </ul>
    </>
  );

}

export default Nav;
